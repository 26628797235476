<template>
<div>
  <label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;" class="label">Snapshots settings</label>
  <label style="display: block;font-weight:normal;margin-bottom:15px;" class="label">You can save the current version of the project or restore it from earlier ones</label>
  <v-form ref="form" @submit.prevent="createSnapshot" style="display: flex;justify-content: center;align-items: center;margin-bottom: 15px;">
    <label style="display:block;margin-right:15px;margin-bottom:0;" class="label">Name</label>
    <div style="flex:5;padding-right: 15px;">
      <v-text-field
          outlined
          v-model="createName"
          placeholder="Type snapshot name"

          :error="errorMessageShow"
          style="margin-top: 25px;max-width:300px;"
          :error-messages="errorCreateName"
          dense
      ></v-text-field>
    </div>
    <div style="flex:1;display: flex;justify-content: flex-end;">
      <v-btn class="object__submit btn btn_primary"
             color="primary"
             outlined
             small
             type="submit"
      >
        Create snapshot
      </v-btn>
    </div>
  </v-form>
  <div style="margin-bottom: 15px;">
    <v-data-table class="table"
                  :disable-sort="$store.state.isMobile"
                  :headers="headers"
                  :items="snapshots"
                  hide-default-footer
    >
      <template v-slot:body="{ items }">
        <tbody v-if="items.length">
        <tr v-for="(item,index) in items" :key="item.id">
          <td class="d-block d-sm-table-cell" style="text-align: center;">{{index+1}}</td>
          <td class="d-block d-sm-table-cell" style="text-align: center;">{{item.name}}</td>
          <td class="d-block d-sm-table-cell" style="text-align: center;">{{formatDate(item.created_at)}}</td>
          <td class="d-block d-sm-table-cell" style="text-align: center;">
            <v-menu offset-y open-on-hover bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    small
                    outlined
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="recoveryOpen(item.id)">
                  <v-list-item-title>Recovery</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteSnap(item.id)" v-if="item.locked === 0">
                  <v-list-item-title style="color:red;">Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            </td>
        </tr>
        </tbody>
        <tbody v-if="items.length === 0">
        <tr>
          <td  colspan="4" style="text-align: center;padding:0 15px;font-weight: normal;">You don't have snapshots yet, it's time to create them</td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
  <PopupConfirm ref="PopupConfirm" @confirm="recovery()" :title="`Are you sure about recovery?`"/>
  <PopupDelete ref="PopupDelete" @confirm="deleteSnapshot()" :title="`Are you sure about delete?`"/>
</div>
</template>

<script>
export default {
  name: "Snapshots",
  components: {
    PopupConfirm:() => import("../../Popup/PopupConfirm"),
    PopupDelete:() => import("../../Popup/PopupDelete"),
  },
  data(){
    return{
      dialog:false,
      createName:'',
      deleteId:'',
      recoveryId:'',
      errorCreateName:"",
      nameRules: [(v) => !!v || 'snapshot name is required'],
      headers:[
        { text: 'ID', value: "id", align: 'center',sortable:true, },
        { text: 'Name', value: "name", align: 'center',sortable:true, },
        { text: 'Created', value: "created_at", align: 'center',sortable:true, },
        { text: 'Options', value: "user_id", align: 'center',sortable:false, },
      ],
      snapshots:[],
      errorMessageShow:false,
    }
  },
  methods:{
    formatDate(date){
      return this.$moment(date).format('DD.MM.yyyy HH:mm')
    },
    recoveryOpen(id){
      this.recoveryId =id;
      this.$refs.PopupConfirm.open();
    },
    deleteSnap(id){
      this.deleteId =id;
      this.$refs.PopupDelete.open();
    },
    async recovery(){
      try{
        const response = await this.$axios.get(`${process.env.VUE_APP_API_URL}recover-from-backup/${this.recoveryId}`);

        if(response.status === 200){
            let newUrl = window.location.origin + window.location.pathname + "?snapshot=" + this.snapshots.find(x => x.id === this.recoveryId).name;
            window.location.href = newUrl;
        }
      }catch (e){
        console.log(e);
      }
    },
    async deleteSnapshot(){
      try{
        const response = await this.$axios.delete(`${process.env.VUE_APP_API_URL}backup/${this.deleteId}`);
        if(response.status === 200){
          let candidate = this.snapshots.find(x => x.id === this.deleteId);
          this.snapshots.splice(this.snapshots.indexOf(candidate),1);
        }
      }catch (e){
        console.log(e);
      }
    },
    async getSnapshots(){
      try{
        const response = await this.$axios.get(`${process.env.VUE_APP_API_URL}backups/${this.$route.params.id}`)
        return response.data;
      }catch (e){
        console.log(e);
      }
    },
    async createSnapshot(){
      if(this.createName){
        let data ={
          name:this.createName,
          user_id:this.$store.state.profile.id,
          hotel_id:this.$route.params.id
        };
        try{
          const response = await this.$axios.post(process.env.VUE_APP_API_URL+'create-backup',data);
          if(response.status === 200){
            this.snapshots.push(response.data);
              this.errorMessageShow = false;
              this.errorCreateName = "";
          }
        }catch (e) {
            this.errorMessageShow = true;
            this.errorCreateName = e.message;
          console.log(e);
        }
      }
      else{
          this.errorMessageShow = true;
          this.errorCreateName = "Can't be empty"
      }
      this.createName = `my snapshot ${this.$moment().format('DD.MM.YYYY')} / ${this.$moment().format('HH:mm')}`
    },
    close(){
      this.dialog=false;
      this.createName = false;
    }
  },
  async mounted() {
    this.createName = `my snapshot ${this.$moment().format('DD.MM.YYYY')} / ${this.$moment().format('HH:mm')}`
    this.snapshots = await this.getSnapshots();
  }
}
</script>

<style scoped>

</style>
