import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"label",staticStyle:{"font-weight":"bold","font-size":"20px","display":"block","margin-bottom":"15px"}},[_vm._v("Snapshots settings")]),_c('label',{staticClass:"label",staticStyle:{"display":"block","font-weight":"normal","margin-bottom":"15px"}},[_vm._v("You can save the current version of the project or restore it from earlier ones")]),_c(VForm,{ref:"form",staticStyle:{"display":"flex","justify-content":"center","align-items":"center","margin-bottom":"15px"},on:{"submit":function($event){$event.preventDefault();return _vm.createSnapshot.apply(null, arguments)}}},[_c('label',{staticClass:"label",staticStyle:{"display":"block","margin-right":"15px","margin-bottom":"0"}},[_vm._v("Name")]),_c('div',{staticStyle:{"flex":"5","padding-right":"15px"}},[_c(VTextField,{staticStyle:{"margin-top":"25px","max-width":"300px"},attrs:{"outlined":"","placeholder":"Type snapshot name","error":_vm.errorMessageShow,"error-messages":_vm.errorCreateName,"dense":""},model:{value:(_vm.createName),callback:function ($$v) {_vm.createName=$$v},expression:"createName"}})],1),_c('div',{staticStyle:{"flex":"1","display":"flex","justify-content":"flex-end"}},[_c(VBtn,{staticClass:"object__submit btn btn_primary",attrs:{"color":"primary","outlined":"","small":"","type":"submit"}},[_vm._v(" Create snapshot ")])],1)]),_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c(VDataTable,{staticClass:"table",attrs:{"disable-sort":_vm.$store.state.isMobile,"headers":_vm.headers,"items":_vm.snapshots,"hide-default-footer":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length)?_c('tbody',_vm._l((items),function(item,index){return _c('tr',{key:item.id},[_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(index+1))]),_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.formatDate(item.created_at)))]),_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center"}},[_c(VMenu,{attrs:{"offset-y":"","open-on-hover":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":"","small":"","outlined":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.recoveryOpen(item.id)}}},[_c(VListItemTitle,[_vm._v("Recovery")])],1),(item.locked === 0)?_c(VListItem,{on:{"click":function($event){return _vm.deleteSnap(item.id)}}},[_c(VListItemTitle,{staticStyle:{"color":"red"}},[_vm._v("Delete")])],1):_vm._e()],1)],1)],1)])}),0):_vm._e(),(items.length === 0)?_c('tbody',[_c('tr',[_c('td',{staticStyle:{"text-align":"center","padding":"0 15px","font-weight":"normal"},attrs:{"colspan":"4"}},[_vm._v("You don't have snapshots yet, it's time to create them")])])]):_vm._e()]}}])})],1),_c('PopupConfirm',{ref:"PopupConfirm",attrs:{"title":"Are you sure about recovery?"},on:{"confirm":function($event){return _vm.recovery()}}}),_c('PopupDelete',{ref:"PopupDelete",attrs:{"title":"Are you sure about delete?"},on:{"confirm":function($event){return _vm.deleteSnapshot()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }